<template>
  <div>
      <span class="mb-2" style="text-align:center; color: black">
          Lista: {{ priceList.priceListName }}
          <br>
          Cliente: {{ selectedClient.name }}
          <br>
          <template v-if="isCashierEnabled">
              <template v-if="cashierConnection">Cajero conectado</template>
              <template v-else>Cajero desconectado</template>
          </template>

      </span>
    <b-card>
      <!-- Scale Connection -->
      <b-row v-if="!isManualScaleEnabled && !isScaleConnected">
        <b-col lg="12" sm="12" class="mt-2">
          <h2 class="mb-2" style="text-align:center; color: #EA5455">
            {{ scaleErrorMsg }}
          </h2>
        </b-col>
      </b-row>
      <!-- SCALE MODULE -->
      <div>
        <!-- RETURN BUTTON -->
        <b-row>
          <b-col lg="12" sm="12" class="align-self-center">
            <b-button
              v-if="showBackButton"
              @click="setBackButton()"
              variant="relief-dark"
              block
              size="lg"
            >
              <feather-icon icon="CornerUpLeftIcon" size="20" class="mr-50" />
              Regresar
            </b-button>
          </b-col>
        </b-row>
        <!-- PRODUCTS ROW -->
        <div
          id="items-div"
          :style="
            showProducts
              ? 'height: 12.7em; overflow: hidden auto'
              : 'height: 16em; overflow: hidden auto'
          "
        >
          <b-overlay :show="loadingProducts" rounded="sm">
            <b-row align-h="center">
              <b-col lg="8" sm="12" class="mt-2">
                <!-- ROW FREQUENT PRODUCTS -->
                <b-row v-if="showFrequentProducts">
                  <b-col
                    v-for="(frequentProducts, index) in frequentProducts"
                    :key="index"
                    lg="3"
                    sm="3"
                    class="mt-2"
                  >
                    <b-button
                      block
                      style="height: 5em; word-wrap: normal;"
                      :pressed.sync="frequentProducts.status"
                      variant="outline-dark"
                      @click="selectProduct(frequentProducts, index)"
                    >
                      <span style="display: block; padding-bottom: 10%">{{ frequentProducts.name }}</span>
                      <span><b>${{frequentProducts.price}}</b></span>
                    </b-button>
                  </b-col>
                </b-row>
                <!-- ROW PRODUCT -->
                <b-row v-if="showProducts">
                  <b-col
                    v-for="(product, index) in products"
                    :key="index"
                    lg="3"
                    sm="3"
                    class="mt-2"
                  >
                    <b-button
                      block
                      style="height: 5em; word-wrap: normal;"
                      :pressed.sync="product.status"
                      variant="outline-dark"
                      @click="selectProduct(product, index)"
                    >
                        <span style="display: block; padding-bottom: 10%">{{ product.name }}</span>
                        <span><b>${{product.price}}</b></span>
                    </b-button>
                  </b-col>
                </b-row>
                <!-- ROW CATEGORY -->
                <b-row v-if="showCategories">
                  <b-col
                    lg="3"
                    sm="3"
                    class="mt-2"
                    v-for="(categoryItems, categoryKey) in priceList.items"
                    :key="categoryKey"
                  >
                    <b-button
                      block
                      style="height: 120%;"
                      variant="relief-dark"
                      @click="selectCategory(categoryItems)"
                    >
                      {{ categoryKey }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
        <!-- SCALE OPTIONS -->
        <b-row class="justify-content-md-center">
          <b-col lg="3" sm="4" class="mt-2">
            <h6 class="mb-2">
              Kilos
            </h6>
            <b-form-input
              @keypress="restrictNumber($event)"
              v-model="scannedItem.quantity"
              type="number"
              :disabled="!isManualScaleEnabled"
            />
          </b-col>
          <b-col lg="2" sm="2" class="mt-2">
            <h6 class="mb-2">
              Agregar
            </h6>
            <b-button
              variant="success"
              block
              @click="addProduct()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <hr class="mt-2 mb-1" />
      <!-- SUMMARY ORDER -->
      <b-row class="mb-1">
        <b-col lg="12" sm="12">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Ajuste</th>
                  <th>Item</th>
                  <th>Peso</th>
                  <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in purchaseOrder.items" :key="index">
                    <td>
                      <b-button variant="info" @click="editProductQuantity(index)">
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.quantity + "kgs" }}</td>
                  <td>
                    <b-button variant="danger" @click="removeProduct(index)">
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="purchaseOrder.items.length === 0"
              class="mt-2"
              style="text-align: center"
            >
              <h6 class="mb-2">
                No hay registros.
              </h6>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- SAVE BUTTON -->
      <b-row align-h="center">
        <b-col lg="12" sm="12" class="mb-1">
          <b-button
            variant="primary"
            block
            @click="createPurchaseOrder()"
            :disabled="purchaseOrder.items.length === 0 || savingOrderEnabled"
            size="lg"
          >
            <b-spinner small v-if="savingOrderEnabled"/>
            TOTAL: ${{ getTotalTicket() }}

          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!--   POS OPTIONS   -->
    <div class="text-center pb-3">
      <b-avatar size="64" variant="danger" class="mr-2" button @click="showModal('logOutDialog')">
          <feather-icon icon="LogOutIcon" size="32" />
      </b-avatar>
      <b-avatar size="64" variant="primary" class="mr-2" button @click="showModal('posSettingsDialog')">
          <feather-icon icon="SettingsIcon" size="32" />
      </b-avatar>
      <b-avatar size="64" variant="danger" class="mr-2" button @click="showModal('restartReadSerialScalePortDialog')">
          <feather-icon icon="RefreshCwIcon" size="32" />
      </b-avatar>
<!--      <b-avatar size="64" variant="warning" class="mr-2" button @click="showModal('clientListDialog')">-->
<!--          <feather-icon icon="AccountPlusIcon" size="32" />-->
<!--      </b-avatar>-->
      <b-avatar size="64" variant="warning" class="mr-2" button @click="showModal('searchClientDialog')">
          <feather-icon icon="AccountPlusIcon" size="32" />
      </b-avatar>
<!--      <b-avatar size="64" variant="warning" class="mr-2" button @click="showModal('orderStatisticsDialog')">-->
<!--          <feather-icon icon="PackageIcon" size="32" />-->
<!--      </b-avatar>-->
      <b-avatar size="64" variant="primary" class="mr-2" button @click="showModal('latestPurchaseOrdersDialog')">
          <feather-icon icon="FileTextIcon" size="32" />
      </b-avatar>
      <br><br><br>
    </div>

  <!--  POS MODALS   -->
    <b-modal id="searchClientDialog"
        title="Busqueda de cliente"
        ok-title="Aceptar"
        cancel-title="Cancelar"
        cancel-variant="outline-secondary"
        centered
        no-close-on-backdrop
        hide-header-close
        @ok="selectClient()"
        :ok-disabled="!searchedClient.companyId"
        @cancel="resetSearchedClient()"
      >
          <b-overlay rounded="sm">
              <h3 style="margin-bottom: 1em">
                  Ingresa el numero de cliente
              </h3>
              <b-form-input
                      @keypress="restrictNumber($event)"
                      type="number"
                      v-model="clientId"
              />
              <b-button variant="primary" block @click="searchClientByShortCompanyId()">
                  Buscar
              </b-button>

              <template v-if="searchedClientError">
                  <h3 style="margin-top: 1em; color: red">
                      El usuario no existe
                  </h3>
              </template>
              <template v-else>
                  <h3 style="margin-top: 1em">
                      {{ searchedClient.name }} <br />
                  </h3>
              </template>
          </b-overlay>
      </b-modal>
    <b-modal id="restartReadSerialScalePortDialog"
      title="Advertencia"
      ok-title="Reiniciar lectura de bascula"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      @ok="restartReadSerialScalePort()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <h2>Solo usar esta accion cuando sea necesario, no jugar con esto.</h2>
      <p style="font-size: medium">Esta acción va a demorar varios segundos.</p>
    </b-modal>
    <b-modal id="editProductQuantityDialog"
      v-if="purchaseOrder.items.length > 0"
      title="Ajuste manual de la cantidad"
      ok-title="Ajustar"
      @ok="adjustProductQuantity()"
      ok-only
      :ok-disabled="purchaseOrder.items[currentIndex].quantity <= 0"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-input-group append="kilos" class="input-group-merge mb-2" v-if="purchaseOrder.items.length > 0">
        <b-form-input
          @keypress="restrictNumber($event)"
          :value="parseFloat(purchaseOrder.items[currentIndex].quantity)"
          readonly
        />
      </b-input-group>
      <div class="num-pad">
        <b-button
          variant="outline-primary"
          v-for="number in numbers.concat('.')" :key="number" @click="addNumber(number)"
          :style="number === 0 ? 'flex-basis: calc(60% + 10px)' : ''"
        >
          {{ number }}
        </b-button>
      </div>
      <div class="clear-button-container">
        <b-button @click="deleteNumber()" variant="outline-secondary">Borrar</b-button>
      </div>
    </b-modal>
    <b-modal id="clientListDialog"
      title="Lista de clientes"
      ok-only
      centered
      size="lg"
      >
        <b-row>
            <b-col
              lg="12"
              sm="12"
              class="mt-2"
              v-for="(client, index) in clients"
              :key="index"
            >
                <b-button
                  block
                  style="height: 5em; word-wrap: normal;"
                  variant="primary"
                  @click="selectClient(client)"
                >
                    {{client.name}}
                </b-button>
            </b-col>
        </b-row>
      </b-modal>
    <b-modal id="posSettingsDialog"
      title="Configuracion del POS"
      @ok="changePosSettings()"
      ok-only
      size="lg"
      centered
      no-close-on-backdrop
      hide-header-close
    >
        <b-button block style="height: 5em; word-wrap: normal;" variant="primary"
                  @click="changeScaleStatus()">
            <template v-if="isManualScaleEnabled">Activar bascula digital</template>
            <template v-else>Activar bascula manual</template>
        </b-button>
        <template v-if="!isMobile()">
            <b-button block style="height: 5em; word-wrap: normal;" variant="primary"
                      @click="changePrinterStatus()">
                <template v-if="!isPrinterEnabled">Activar impresora</template>
                <template v-else>Desactivar impresora</template>
            </b-button>
        </template>
        <b-button block style="height: 5em; word-wrap: normal;" variant="primary"
                  @click="changeCashierStatus()">
            <template v-if="!isCashierEnabled">Activar cobro con cajero</template>
            <template v-else>Desactivar cobro con cajero</template>
        </b-button>
    </b-modal>
    <b-modal id="logOutDialog"
       title="Cerrar sesion del punto de venta"
       @ok="logout()"
       ok-title="Cerrar sesion"
       cancel-title="Cancelar"
       size="lg"
       centered
       no-close-on-backdrop
       hide-header-close
    >
    </b-modal>
    <!--
    <b-modal id="orderStatisticsDialog"
      title="Estadísticas de las ordenes"
      ok-title="Cerrar"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
      size="lg"
    >
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="LayersIcon" size="18" class="mr-50" />
            <span class="font-weight-bold"> Corte Diario </span>
          </template>
          <b-overlay :show="cutTabOverlay" rounded="sm">
            <div style="height: 30em; overflow-y: auto">
              <b-table
                striped
                responsive
                :items="cutTabData"
                :fields="cutTabColumns"
                :sort-by.sync="sortCut"
                :sort-desc="true"
                show-empty
                empty-text="No hay registros"
                class="position-relative"
              >
                <template #cell(realQuantity)="data">
                  {{ formatNumber(data.value) }} kgs
                </template>
              </b-table>
            </div>
          </b-overlay>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
            <span class="font-weight-bold"> Inventario </span>
          </template>
          <b-overlay :show="inventoryTabOverlay" rounded="sm">
            <div style="height: 30em; overflow-y: auto">
              <b-table
                striped
                responsive
                :items="inventoryTabData"
                :fields="inventoryTabColumns"
                :sort-by.sync="sortInventory"
                :sort-desc="true"
                show-empty
                empty-text="No hay registros"
                class="position-relative"
              >
                <template #cell(quantity)="data">
                  {{ formatNumber(data.value) }} kgs
                </template>
              </b-table>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </b-modal>
     -->
    <b-modal id="latestPurchaseOrdersDialog"
      title="Últimas Órdenes"
      ok-title="Cerrar"
      ok-only
      centered
      size="lg"
    >
      <b-overlay :show="loadingLatestPurchaseOrders" rounded="sm">
        <b-table
          striped
          responsive
          :items="latestPurchaseOrders"
          :fields="latestPurchaseOrdersColumns"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >
           Fecha
          <template #cell(createdAt)="{item}">
              {{ new Date(item.createdAt) | moment("DD/MM/YYYY - H:mm:ss") }}
          </template>

          <!-- Total -->
          <template #cell(total)="{item}">
              Total: ${{ item.total }}
          </template>

          <!-- Acción -->
          <template #cell(details)="row">
            <b-button variant="light" size="sm" @click="row.toggleDetails">
              <feather-icon
                :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
                class="mr-50"
              />
              {{ row.detailsShowing ? "Ocultar" : "Mostrar" }}
            </b-button>
          </template>

          <!-- Detalles -->
          <template #row-details="{item}">
              <b-card>
                  <b-row>
                      <b-col cols="12">
                          <div class="table-responsive">
                              <table class="table">
                                  <tbody>
                                  <tr>
                                      <td>Material</td>
                                      <td>Neto</td>
                                      <td>Precio</td>
                                      <td>Subtotal</td>
                                      <td v-if="$can('read', 'SnapshotsWeighing')">Foto</td>
                                  </tr>
                                  <tr
                                          v-for="(item, index) in item.items"
                                          :key="index"
                                  >
                                      <td><b> {{ item.name }} </b></td>
                                      <td>{{ item.quantity + "kgs"}}</td>
                                      <td>${{ item.price }}</td>
                                      <td>${{ item.subtotal }}</td>
                                  </tr>
                                  </tbody>
                              </table>
                              <div>
                              </div>
                          </div>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col cols="4">
                      </b-col>
                      <b-col cols="4" v-if="isMobile() || isPrinterEnabled">
                          <b-button variant="primary" size="xl" @click="printPurchaseOrder(item)">
                              <feather-icon icon="PrinterIcon"/>
                              Reimprimir orden
                          </b-button>
                      </b-col>
                      <b-col cols="4">
                      </b-col>
                  </b-row>
              </b-card>

          </template>
        </b-table>
      </b-overlay>
    </b-modal>
  </div>
</template>


<style lang="scss" scoped>
@import 'Scale.css';
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import vSelect from "vue-select";
import { v4 as uuidv4 } from 'uuid';

import {
  BCard,
  BFormSelect,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BFormTextarea,
  BButton,
  BModal,
  BInputGroupAppend,
  BSpinner,
  BOverlay,
  BFormSpinbutton,
  BFormCheckbox,
  BAvatar,
  VBTooltip,
  BTable,
  BInputGroup,
  BFormGroup,
  BTab,
  BTabs,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import notification from "./notification.mp3";

import io from "socket.io-client";
import EchartLine from "@/views/charts-and-maps/charts/echart/EchartLine.vue";
import useJwt from "@/auth/jwt/useJwt";
import {Auth} from "aws-amplify";
import {initialAbility} from "@/libs/acl/config";

export default {
  components: {
      EchartLine,
    vSelect,
    BCard,
    BFormSelect,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BFormTextarea,
    BButton,
    BModal,
    BSpinner,
    BOverlay,
    BFormSpinbutton,
    BFormCheckbox,
    BAvatar,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BTab,
    BTabs,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      socket: undefined,
      isManualScaleEnabled: false,
      isPrinterEnabled: false,
      isCashierEnabled: false,
      isScaleConnected: false,
      loadingProducts: true,
      savingOrderEnabled: false,
      showBackButton: false,
      showCategories: true,
      showProducts: false,
      showFrequentProducts: false,
      loadingLatestPurchaseOrders: true,
      cashierConnection: false,
      clientId: undefined,
      searchedClientError: false,
      selectedClientName: undefined,

      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      scaleErrorMsg: "Bascula no conectada",

      frequentProducts: [],
      products: [],
      clients: [],
      latestPurchaseOrders: [],
      priceLists: [],
      isLogOutRequested: false,
      user: JSON.parse(localStorage.getItem("user")),
      timerCount: this.$BigNumber(0),
      currentIndex: this.$BigNumber(0),
      latestPurchaseOrdersColumns: [
          { key: "createdAt", label: "Fecha de creación", sortable: true },
          { key: "total", label: "Total", sortable: true },
          { key: "details", label: "Detalles", sortable: false },
      ],

      posDetails: {
          posId: undefined,
          userId: undefined,
          scaleApiUrl: undefined,
          companyId: undefined,
          cashierApiUrl: undefined,
          cashierId: undefined,
          expirationDaysOfPaymentAuthorization: undefined
      },
      scannedItem: {
          companyProductCatalogId: null,
          name: null,
          quantity: null,
          price: null,
      },
      selectedClient: {
          name: undefined,
          companyId: undefined
      },
      searchedClient: {
          name: undefined,
          companyId: undefined
      },
      priceList: {
          priceListName: "Cargando..."
      },

      purchaseOrder: {
          purchaseOrderId: null,
          buyerCompanyId: null,
          sellerCompanyId: null,
          iva: this.$BigNumber(0),
          subtotal: this.$BigNumber(0),
          total: this.$BigNumber(0),
          items: [],
          isCashierEnabled: false,
          createdAt: undefined,
          modifiedAt: undefined,
          // deliveryDate: this.$moment(new Date()).valueOf(),
          // idPaymentStatus: 10,
          // idShippingStatus: 7,
          // idUser: null,
          // paymentType: 3,
          // creditDays: this.$BigNumber(0),
          // deadlinePayment: null,
          // subTotalLogisticPrice: this.$BigNumber(0),
      },

      paymentAuthorization: {
          paymentAuthorizationId: null,
          cashierId: null,
          purchaseOrderId: null,
          amount: this.$BigNumber(0),
          partialAmountPaid: this.$BigNumber(0),
          totalAmountDue: this.$BigNumber(0),
          expiresAt: null,
          createdAt: undefined,
          modifiedAt: undefined,
      },


      //   TODO FIX START
      cutTabColumns: [
        { key: "alias", label: "Material", sortable: false },
        { key: "realQuantity", label: "Kilos", sortable: true },
      ],
      sortCut: "realPrice",
      cutTabOverlay: true,
      cutTabData: [],

      inventory: [],
      inventoryTabColumns: [
        { key: "name", label: "Material", sortable: true },
        { key: "quantity", label: "Kilos", sortable: true },
      ],
      sortInventory: "quantity",
      inventoryTabOverlay: true,
      inventoryTabData: [],

      //   TODO FIX END
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  created() {
    this.isManualScaleEnabled = this.getBooleanFromLocalStorage('isManualScaleEnabled');
    this.isPrinterEnabled = this.getBooleanFromLocalStorage('isPrinterEnabled');
    this.isCashierEnabled = this.getBooleanFromLocalStorage('isCashierEnabled');
    this.getUserInfoApi();
    this.removeDataFromLocalStorage("items")


    this.intervalPosDetails = setInterval(this.getUserInfoApi, 20000);
    this.intervalUnsavedOrders = setInterval(this.saveUnsavedOrders, 30000);
    this.intervalSendMissingPaymentAuthorizations = setInterval(this.sendMissingPaymentAuthorizationsToCashier, 30000);
    this.intervalClients = setInterval(this.getClientsByCompanyIdApi, 30000); // Cada 1 mins
    this.intervalLatestPurchaseOrders = setInterval(this.findLatest10PurchaseOrdersByPosIdApi, 30000); // Cada 1 mins
  },
  beforeRouteLeave(to, from, next) {
      if (!this.isLogOutRequested) {
          // Evitar que se salgan de la pantalla y perder datos
          const answer = window.confirm(
              "¿Seguro que desea salir? Los cambios no guardados se perderán."
          );
          if (answer) {
              // if (this.timer) clearInterval(this.timer);
              if (this.intervalPosDetails) clearInterval(this.intervalPosDetails);
              if (this.intervalUnsavedOrders) clearInterval(this.intervalUnsavedOrders);
              if (this.intervalSendMissingPaymentAuthorizations) clearInterval(this.intervalSendMissingPaymentAuthorizations);
              if (this.intervalClients) clearInterval(this.intervalClients);
              if (this.intervalPriceList) clearInterval(this.intervalPriceList);
              if (this.intervalLatestPurchaseOrders) clearInterval(this.intervalLatestPurchaseOrders);
              next();
          } else {
              next(false);
          }
      } else {
          next();
      }
  },
  computed: {
    lastItem() {
      return this.purchaseOrder.items[0];
    },
  },
  methods: {
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    removeDataFromLocalStorage(key){
        localStorage.removeItem(key);
    },
    addDataToLocalStorage(key, data){
        localStorage.setItem(key, JSON.stringify(data));
    },
    getBooleanFromLocalStorage(key){
        return (JSON.parse(localStorage.getItem(key)) ?? false);
    },
    getDataFromLocalStorage(key){
        return (JSON.parse(localStorage.getItem(key)) ?? []);
    },
    verifyCashierConnection(){
      if(!this.isCashierEnabled) return

      this.$axios.post(this.posDetails.cashierApiUrl + 'verifyCashierConnection')
      .then((res) => {
              this.cashierConnection = true;
      })
      .catch((error) => {
          this.cashierConnection = false;
      })
    },
    getClientsByCompanyIdApi() {
      this.clients = []
      return new Promise((resolve, reject) => {
          this.$axios
              .post(this.$services + "pos/getClientsByCompanyIdApi", {
                  companyId: this.posDetails.companyId,
              })
              .then((res) => {
                  if (!res.data.error) {
                      this.clients = res.data;
                      this.addDataToLocalStorage("clients", this.clients)
                  }
                  resolve();
              })
              .catch((error) => {
                  reject(error);
                  this.clients = this.getDataFromLocalStorage("clients")
              })
      });
    },
    updatePriceLists(priceList) {
        const existingPriceLists = this.getDataFromLocalStorage("priceLists")
        const priceListIndex = existingPriceLists.findIndex(pl => pl.priceListId === priceList.priceListId);
        //TODO REVIEW THIS CODE

        if (priceListIndex !== -1) {
            existingPriceLists[priceListIndex] = priceList;
        } else {
            existingPriceLists.push(priceList);
        }
      this.addDataToLocalStorage("priceLists", existingPriceLists)
    },
    getPriceListByCompanyClientIdApi() {
      this.priceList = []
      this.frequentProducts = []
      this.loadingProducts = true;
      this.showFrequentProducts = false;
      return new Promise((resolve, reject) => {
        this.$axios
            .post(this.$services + "pos/getPriceListByCompanyClientIdApi", {
                clientCompanyId: this.selectedClient.companyId,
                companyId: this.posDetails.companyId,
            })
          .then((res) => {
            if (!res.data.error) {
              this.priceList = res.data;
              this.updatePriceLists(this.priceList)

              if (this.priceList.items["FREQUENTLY"]) {
                  this.frequentProducts = this.priceList.items["FREQUENTLY"];
                  delete this.priceList.items["FREQUENTLY"]
              }
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
            const existingPriceLists = this.getDataFromLocalStorage("priceLists")

            let priceList;
            if (this.selectedClient.companyId)
                priceList = existingPriceLists.find(pl => pl.companyIds.includes(this.selectedClient.companyId));
            else
                priceList = existingPriceLists.find(pl => pl.priceListName === "Publico General");

            if (!priceList) {
                this.showSwal("Aviso", "La lista de precio no esta disponible en modo offline por el momento"
                    , "info");
                priceList = existingPriceLists.find(pl => pl.priceListName === "Publico General");
            }

            this.priceList = priceList;
            if (this.priceList.items["FREQUENTLY"]) {
                this.frequentProducts = this.priceList.items["FREQUENTLY"];
                delete this.priceList.items["FREQUENTLY"]
            }
          })
          .finally(() => {
            this.loadingProducts = false;
            if (!this.showBackButton)
              this.showFrequentProducts = true;
          });
      });
    },
    selectCategory(categoryItems) {
          this.setBackButton();

          this.products = categoryItems;
          this.showCategories = false;
          this.showFrequentProducts = false;
          this.showProducts = true;
          this.showBackButton = true;
  },
    selectProduct(product, index) {
      this.resetButtonsStatus(index);

      this.scannedItem.companyProductCatalogId = product.companyProductCatalogId;
      this.scannedItem.price = this.$BigNumber(product.price).toNumber();
      this.scannedItem.name = product.name;

      this.scrollItems();
    },
    findLatest10PurchaseOrdersByPosIdApi() {
      this.loadingLatestPurchaseOrders = true;
      this.$axios
        .post(this.$services + "pos/findLatest10PurchaseOrdersByPosIdApi", {
            posId: this.posDetails.posId,
        })
        .then((res) => {
          if (!res.data.error) {
            this.latestPurchaseOrders = res.data;
            this.addDataToLocalStorage("latestPurchaseOrders", this.latestPurchaseOrders)
          }
        })
        .catch((error) => {
          this.latestPurchaseOrders = this.getDataFromLocalStorage("latestPurchaseOrders")
        })
        .finally(() => {
          this.loadingLatestPurchaseOrders = false;
        });
    },
    notifyUser() {
      this.timerCount += 1;

      // Ya 5 alertas
      if (this.timerCount >= 5) {
        clearInterval(this.timer);
        this.timerCount = 0;
        // this.closeTicket();

        this.showSwal("Notificación", "La orden se ha cerrado automáticamente.", "info");
        return;
      }

      this.$swal({
        title: "¡Cuidado!",
        text:
          "Tienes una orden pendiente por cerrar. En " +
          (5 - this.timerCount) +
          " " +
          (this.timerCount === 4 ? "minuto" : "minutos") +
          " se cerrará automáticamente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Voy a revisarlo",
        cancelButtonText: "Posponer 5 minutos",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.dismiss === "cancel") {
          // Posponer timer
          clearInterval(this.timer);
          this.timer = setInterval(this.notifyUser, 300000); // 5 minutos
          this.timerCount = 0;
          return;
        }
      });

      const audio = new Audio(notification);
      audio.play();

      clearInterval(this.timer);
      this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
    },
    addProduct() {
          if (this.checkMissingParameters()) {
              this.showSwal("¡Cuidado!", "Por favor, seleccione algún material a agregar con su debido peso.", "error");
              return;
          }

          // Fix to avoid duplicate
          if (this.purchaseOrder.items.length > 0 && (this.$BigNumber(this.lastItem.quantity).isEqualTo(this.scannedItem.quantity) || this.lastItem.companyProductCatalogId === this.scannedItem.companyProductCatalogId)) {
              this.showSwal("¡Cuidado!", "Estás a punto de ingresar un posible item duplicado.", "warning", {
                  showCancelButton: true,
              }, (userConfirms) => {
                  // Borrar el último item agregado si el usuario se arrepiente
                  if (!userConfirms) {
                      this.removeProduct(0);
                      return;
                  }
              });
          }

          const subtotal = (this.$BigNumber(this.scannedItem.price).multipliedBy(this.scannedItem.quantity)).toNumber()

          if (subtotal < 0.5) {
              this.showSwal("¡Cuidado!", "Por favor, el resultado a pagar es $0 confirme peso y precio", "error");
              return;
          }

          let newItem = {
              companyProductCatalogId: this.scannedItem.companyProductCatalogId,
              name: this.scannedItem.name,
              quantity: this.scannedItem.quantity,
              price: this.scannedItem.price,
              subtotal: subtotal,
          };
        
          this.purchaseOrder.items.unshift(newItem);

          let items = JSON.parse(localStorage.getItem("items")) ?? [];
          items.push(newItem);
          localStorage.setItem("items", JSON.stringify(items));

          // Empezar contador para notificar orden abierta
          // if (!this.timer) {
          //   this.timerCount = 0;
          //   this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
          // } else {
          //   clearInterval(this.timer);
          //   this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
          // }

          this.showFrequentProducts = true;
          this.scannedItem.quantity = 0;
          this.scannedItem.units = null;
          this.scannedItem.name = null;
          this.setBackButton();
      },
    removeProduct(index) {
      this.purchaseOrder.items.splice(index, 1);
      this.removeDataFromLocalStorage("items")
      this.addDataToLocalStorage("items", this.purchaseOrder.items)
      if (this.purchaseOrder.items.length <= 0) {
        clearInterval(this.timer);
        this.timerCount = 0;
      }
    },
    getTotalTicket() {
      let total = this.$BigNumber(0);

      this.purchaseOrder.items.forEach((element) => {
        total = total.plus(element.subtotal);
      });

      // TODO check iva
      let iva = total.multipliedBy(1).toNumber();
      /*
       TODO check if we are gonna round directly over the order
        cause we can use how many cent we are saving if we the real price
        but the issue there is we have to use the same rule python/server
        to round to down
      */
      const totalFormatted = this.formatNumber(total);
      const totalFormattedWithNoComma = this.$BigNumber(totalFormatted.replace(",",""));

      const ivaFormatted = this.formatNumber(iva);
      const ivaFormattedWithNoComma = this.$BigNumber(ivaFormatted.replace(",",""));

      this.purchaseOrder.subtotal = totalFormattedWithNoComma;
      this.purchaseOrder.total = totalFormattedWithNoComma;
      this.purchaseOrder.iva = ivaFormattedWithNoComma;
      return totalFormatted;
    },
    async saveUnsavedOrders() {
      let unsavedOrders = this.getDataFromLocalStorage("unsavedOrders")

      if (!unsavedOrders.length === 0) return;

      let unsavedOrdersAttempt = []
      for (const unsavedOrder of unsavedOrders) {
          try {
              await this.$axios.post(this.$services + "pos/createPurchaseOrderApi", {
                  purchaseOrder: unsavedOrder.purchaseOrder,
                  paymentAuthorization: unsavedOrder.paymentAuthorization,
              })
          } catch (e) {
              unsavedOrdersAttempt.push(unsavedOrder)
          }
      }

      let tempUnsavedOrders = this.getDataFromLocalStorage("unsavedOrders")

      const newUnsavedOrdersTemp = tempUnsavedOrders.filter(obj2 => !unsavedOrders.some(obj1 =>
          obj1.purchaseOrder.purchaseOrderId === obj2.purchaseOrder.purchaseOrderId
      ));

      const newUnsavedOrders = unsavedOrdersAttempt.concat(newUnsavedOrdersTemp)
      this.removeDataFromLocalStorage("unsavedOrders")
      this.addDataToLocalStorage("unsavedOrders", newUnsavedOrders)
    },
    selectClient(){
        this.purchaseOrder.items = [];
        this.selectedClient = this.searchedClient;
        this.getPriceListByCompanyClientIdApi();
        this.resetSearchedClient();
        // this.hideModal("clientListDialog")
    },
    resetSearchedClient(){
        this.searchedClientError = false;
        this.searchedClient = {
            name: undefined,
            companyId: undefined
        };
    },
    searchClientByShortCompanyId(){
        this.searchedClientError = false;
        this.searchedClient = this.clients.find(client => client.shortCompanyId === parseInt(this.clientId))

        if (!this.searchedClient) {
            this.searchedClientError = true;
            this.searchedClient = {
                name: undefined,
                companyId: undefined
            };
        }
        this.clientId = undefined
    },
    isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    },
    printPurchaseOrder(purchaseOrder){
        if (this.isMobile()) {
            this.printPurchaseOrderInAndroidThermalPrinter(purchaseOrder)
        } else {
            if (!this.isPrinterEnabled) return
            this.printPurchaseOrderInThermalPrinter(purchaseOrder)
        }
    },
    printPurchaseOrderInThermalPrinter(purchaseOrder){
        this.socket.emit("print_order", purchaseOrder);
    },
    printPurchaseOrderInAndroidThermalPrinter(purchaseOrder){
        window.heliosposapp.printPurchaseOrderInAndroid(JSON.stringify(purchaseOrder));
    },
    getUserInfoApi(){
        this.$axios.post(this.$services + "pos/getUserInfoApi", {
            userId: this.user.userId,
        })
        .then((res) => {
            if (!res.data.error) {
                this.posDetails = res.data;
                this.removeDataFromLocalStorage("posDetails")
                this.addDataToLocalStorage("posDetails", this.posDetails)
            }
        })
        .catch((error) => {
          this.posDetails = this.getDataFromLocalStorage("posDetails")
        }).finally(()=> {
            this.getClientsByCompanyIdApi();
            // this.getPriceListByCompanyClientIdApi();
            this.findLatest10PurchaseOrdersByPosIdApi();
            this.verifyCashierConnection();
            if (!this.socket) {
                this.changePosSettings()
            }
            if (this.priceList.priceListName === "Cargando...")
              this.showModal('searchClientDialog')
        })
    },
    uuidToShortUuid(uuid) {
      return uuid.slice(0,8)
    },
    createPurchaseOrder() {
        if (this.purchaseOrder.items.length === 0) {
            this.showSwal("¡Cuidado!", "Por favor, seleccione algun material a agregar.", "error");
            return;
        }

        let sellerCompanyId;
        let sellerCompanyName;

        if (!this.selectedClient.companyId) {
            sellerCompanyId = (this.clients.find(client => client.name === "Publico General")).companyId
            sellerCompanyName = "Publico General";
        } else {
            sellerCompanyId = this.selectedClient.companyId;
            sellerCompanyName = this.selectedClient.name;
        }

        const currentDate = new Date()

        this.purchaseOrder.purchaseOrderId = uuidv4();
        this.purchaseOrder.shortPurchaseOrderId = this.uuidToShortUuid(this.purchaseOrder.purchaseOrderId)
        this.purchaseOrder.sellerCompanyId = sellerCompanyId;
        this.purchaseOrder.sellerCompanyName = sellerCompanyName

        this.purchaseOrder.buyerCompanyId = this.posDetails.companyId;
        this.purchaseOrder.buyerCompanyName = this.user.companyName;
        this.purchaseOrder.userId = this.user.userId;
        this.purchaseOrder.posId = this.user.posId;
        this.purchaseOrder.scaleId = this.user.scaleId;
        // Only for offline purpose and show timezone date
        this.purchaseOrder.createdAt = currentDate;
        this.purchaseOrder.modifiedAt = currentDate;

        this.paymentAuthorization.paymentAuthorizationId = uuidv4();
        this.paymentAuthorization.purchaseOrderId = this.purchaseOrder.purchaseOrderId;
        this.paymentAuthorization.companyId = this.posDetails.companyId;
        this.paymentAuthorization.userId = this.user.userId;
        this.paymentAuthorization.cashierId = undefined;
        this.paymentAuthorization.amount = this.purchaseOrder.total;
        this.paymentAuthorization.totalAmountDue = this.purchaseOrder.total;
        this.paymentAuthorization.createdAt = currentDate;
        this.paymentAuthorization.modifiedAt = currentDate;

        if (this.isCashierEnabled) {
            this.purchaseOrder.paymentAuthorizationId = this.paymentAuthorization.paymentAuthorizationId
            this.paymentAuthorization.expiresAt = this.$moment().add(this.posDetails.expirationDaysOfPaymentAuthorization,"days").toISOString()
            this.purchaseOrder.isCashierEnabled = true;
            this.paymentAuthorization.cashierId = this.posDetails.cashierId;
        }


        const order = {
            purchaseOrder: this.purchaseOrder,
            paymentAuthorization: this.paymentAuthorization
        };
        console.log(`order`, order)

        this.createPurchaseOrderApi(order)
    },
    createPurchaseOrderApi(order){
      console.log('Guardando orden...');
      console.log(order)

      this.savingOrderEnabled = true

      this.$axios
          .post(this.$services + "pos/createPurchaseOrderApi", {
              purchaseOrder: order.purchaseOrder,
              paymentAuthorization: order.paymentAuthorization,
          })
          .then((res) => {
              if (!res.data.error) {
                  this.showToast("success", String("¡Ticket cerrado con éxito!"));
              }
          })
          .catch((error) => {
              let newUnsavedOrders = this.getDataFromLocalStorage("unsavedOrders")
              newUnsavedOrders.push(order)
              this.removeDataFromLocalStorage("unsavedOrders")
              this.addDataToLocalStorage("unsavedOrders", newUnsavedOrders)

              this.latestPurchaseOrders.unshift(order.purchaseOrder);
              this.addDataToLocalStorage("latestPurchaseOrders", this.latestPurchaseOrders)

              this.showToast("danger", String("Orden guardada en local, se subira cuando haya internet"));
          })
          .finally(() => {
              this.sendPaymentAuthorizationToCashier(order.paymentAuthorization)
              this.savingOrderEnabled = false

              this.printPurchaseOrder(order.purchaseOrder)

              clearInterval(this.timer);
              this.timerCount = 0;
              this.selectedClient = {
                  name: undefined,
                  companyId: undefined
              };
              this.purchaseOrder.items = [];
              this.purchaseOrder.isCashierEnabled = false;
              this.scannedItem.companyProductCatalogId = null;
              this.scannedItem.quantity = 0;
              this.scannedItem.price = 0;
              this.total = 0

              // this.getPriceListByCompanyClientIdApi()
              this.findLatest10PurchaseOrdersByPosIdApi();
              this.showModal('searchClientDialog')
              this.priceList = []
              this.frequentProducts = []
              this.loadingProducts = true;
              this.showFrequentProducts = false;
              // this.getInventory();
          });
    },
    sendPaymentAuthorizationToCashier(paymentAuthorization){
        if  (!this.isCashierEnabled) return
        this.$axios.post(this.posDetails.cashierApiUrl + 'sendPaymentAuthorizationToCashier', paymentAuthorization)
        .catch((error) => {
            let newUnsavedPaymentAuthorizations = this.getDataFromLocalStorage("unsavedPaymentAuthorizations")
            newUnsavedPaymentAuthorizations.push(paymentAuthorization)
            this.removeDataFromLocalStorage("unsavedPaymentAuthorizations")
            this.addDataToLocalStorage("unsavedPaymentAuthorizations", newUnsavedPaymentAuthorizations)
            this.showToast("danger", String("Error al enviar pago al cajero, revise conexion"));
        })
    },
    async sendMissingPaymentAuthorizationsToCashier(){
        let unsavedPaymentAuthorizations = this.getDataFromLocalStorage("unsavedPaymentAuthorizations")

        if (!unsavedPaymentAuthorizations.length === 0) return;

        let unsavedPaymentAuthorizationsAttempt = []
        for (const unsavedPaymentAuthorization of unsavedPaymentAuthorizations) {
            try {
                await this.$axios.post(this.posDetails.cashierApiUrl + 'sendPaymentAuthorizationToCashier', unsavedPaymentAuthorization)
            } catch (e) {
                unsavedPaymentAuthorizationsAttempt.push(unsavedPaymentAuthorization)
            }
        }

        let tempUnsavedPaymentAuthorizations = this.getDataFromLocalStorage("unsavedPaymentAuthorizations")

        const newUnsavedPaymentAuthorizationsTemp = tempUnsavedPaymentAuthorizations.filter(obj2 => !unsavedPaymentAuthorizations.some(obj1 =>
            obj1.paymentAuthorizationId === obj2.paymentAuthorizationId
        ));
        const newUnsavedPaymentAuthorizations = unsavedPaymentAuthorizationsAttempt.concat(newUnsavedPaymentAuthorizationsTemp)
        this.removeDataFromLocalStorage("unsavedPaymentAuthorizations")
        this.addDataToLocalStorage("unsavedPaymentAuthorizations", newUnsavedPaymentAuthorizations)
    },
    adjustProductQuantity(){
      this.purchaseOrder.items[this.currentIndex].quantity = this.$BigNumber(this.purchaseOrder.items[this.currentIndex].quantity).toNumber();
      const quantity = this.purchaseOrder.items[this.currentIndex].quantity;
      const price = this.purchaseOrder.items[this.currentIndex].price;
      this.purchaseOrder.items[this.currentIndex].subtotal = this.$BigNumber(quantity).multipliedBy(price).toNumber();
      this.removeDataFromLocalStorage("items")
      this.addDataToLocalStorage("items", this.purchaseOrder.items)
    },
    addNumber(number) {
      this.purchaseOrder.items[this.currentIndex].quantity += String(number);
    },
    deleteNumber() {
        this.purchaseOrder.items[this.currentIndex].quantity = this.$BigNumber(String(this.purchaseOrder.items[this.currentIndex].quantity).slice(0,-1)).toNumber();

        if (isNaN(this.purchaseOrder.items[this.currentIndex].quantity)){
            this.purchaseOrder.items[this.currentIndex].quantity = 0
        }

    },
    checkMissingParameters() {
      return this.scannedItem.companyProductCatalogId == null ||
          ((this.scannedItem.quantity == null || this.scannedItem.quantity === "") &&
              this.scannedItem.units == null) ||
          this.scannedItem.price == null ||
          this.scannedItem.price === "";
    },
    showModal(modalName) {
      this.$bvModal.show(modalName);
    },
    hideModal(modalName) {
        this.$bvModal.hide(modalName);
    },
    scrollItems() {
      let div = document.getElementById("items-div");
      div.scrollTop = 0;
    },
    setBackButton() {
        this.products = [];
        this.scannedItem = {
            quantity: undefined
        };

        this.showCategories = true;
        this.showFrequentProducts = true;

        this.showProducts = false;
        this.showBackButton = false;

        this.resetButtonsStatus();
        this.scrollItems();
    },
    resetButtonsStatus(ignoreIndex=-1) {
          // Deseleccionar los botones de los items frequentes
          this.frequentProducts.forEach((frequent, index) => {
              if (index !== ignoreIndex) frequent.status = false;
          });

          // Deseleccionar los botones del resto de items
          Object.keys(this.priceList.items).forEach(key => {
              this.priceList.items[key].forEach((item, index) => {
                  if (item !== ignoreIndex) this.priceList.items[key][index].status = false;
              });
          });
  },
    editProductQuantity(index) {
        this.currentIndex = index;
        this.showModal("editProductQuantityDialog");
    },
    showSwal(title, text, icon, options={}, callback=null) {
      this.$swal({
        // Configuración general del modal
        title: title,
        text: text,
        icon: icon,

        // Opciones extras
        ...options,

        // Botones de aceptar o cancelar
        confirmButtonText: "Entendido",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary mr-2",
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (callback && typeof callback === "function") {
          callback(result.isConfirmed);
        }
      });
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notificación",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    restartReadSerialScalePort(){
        this.socket.emit("restart_read_serial_scale_port", 'restart');
    },
    formatNumber(value) {
      const number = this.$BigNumber(value);
      return number.toFormat(0, this.$BigNumber.ROUND_HALF_UP);
      // return number.toFormat(0, this.$BigNumber.ROUND_DOWN);
    },
    restrictNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      let stringValue = event.target.value.toString();
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || stringValue.indexOf(".") !== -1)
      ) {
        // 46 is dot
        event.preventDefault();
      }
    },
    changePosSettings(){
        if (!this.isManualScaleEnabled || this.isPrinterEnabled) {
            this.closeSocket()  //  Close previous socket connection
            this.connectSocket()
        } else if(this.isManualScaleEnabled && !this.isPrinterEnabled) {
            this.closeSocket()
        }
    },
    changeScaleStatus(){
        this.isManualScaleEnabled = !this.isManualScaleEnabled
        this.addDataToLocalStorage("isManualScaleEnabled", this.isManualScaleEnabled)
    },
    changePrinterStatus(){
        this.isPrinterEnabled = !this.isPrinterEnabled
        this.addDataToLocalStorage("isPrinterEnabled", this.isPrinterEnabled)
    },
    changeCashierStatus(){
        this.isCashierEnabled = !this.isCashierEnabled
        this.addDataToLocalStorage("isCashierEnabled", this.isCashierEnabled)
    },
    connectSocket(){
        console.log(`Opening socket try to connect to ${this.posDetails.scaleApiUrl}`)

         this.socket = io.connect(this.posDetails.scaleApiUrl, {
            // const socket = io.connect("ws://192.168.1.100:5000", {
            // cors: {
            //   origin: "https://calm-tree-0f70d8510.1.azurestaticapps.net",
            //   methods: ["GET", "POST"],
            //   credentials: true
            // },
            transports: ["websocket", "polling", "flashsocket"],
            allowEIO3: true,
            upgrade: false,
            secure: true,
            rejectUnauthorized: false,
        });
         console.log(`this.socket`, this.socket)

        this.socket.on("connect", () => {
            this.isScaleConnected = false;
            this.scaleErrorMsg = "Conectando con bascula...";
            this.scaleErrorMsg = this.getDataFromLocalStorage('scaleErrorMsg');
            console.log(this.socket.id);
        });

        this.socket.on("disconnect", () => {
            this.isScaleConnected = false;
            this.scaleErrorMsg = "Bascula no conectada";
            this.scaleErrorMsg = this.getDataFromLocalStorage('scaleErrorMsg');
        });

        this.socket.on("scale_error", (error_msg) => {
            this.isScaleConnected = false;
            this.scaleErrorMsg = error_msg;
            this.addDataToLocalStorage("scaleErrorMsg", this.scaleErrorMsg)
        });

        this.socket.on('read_scale_data', (weight) => {
            if (!this.isManualScaleEnabled){
                this.isScaleConnected = true;
                this.scannedItem.quantity = this.$BigNumber(weight).toNumber();
            }
        });

        this.socket.on("snapshot", (msg) => {
            if (!msg.error) {
                this.purchaseOrder.items[0].snapshot = msg.data.base64;
            }
        });
    },
    closeSocket(){
      if (this.socket) {
          this.socket.disconnect();
          console.log("Socket connection closed.");
      } else {
          console.log("Socket is not connected.");
      }
    },
    async logout() {
        this.isLogOutRequested = true;
        // Remove user from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove user from localStorage
        localStorage.removeItem('user')
        localStorage.removeItem('token')

        await Auth.signOut()

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        await this.$router.push({name: 'auth-login'})
    },
  },
};
</script>
